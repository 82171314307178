<template>
  <div>
    <b-card
      no-body
      class="card-statistics"
    >
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
            v-for="item in statisticsData"
            :key="item.icon"
            xl="3"
            sm="6"
            :class="item.customClass"
          >
            <b-media no-body>
              <b-media-aside

                class="mr-2"
              >
                <v-icon
                  scale="4"
                  :name="item.icon"
                  :class="`text-${item.color}`"
                />
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ item.title }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.subtitle }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card>
      <b-card-header>
        INFORME DE ASISTENCIA MENSUAL
      </b-card-header>
      <b-card-body>
        <apexchart
          type="bar"
          height="350"
          :options="chartData.chartOptions"
          :series="chartData.series"
        />
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
  import {
    BCard, BCardText, BCardHeader, BCardBody, BRow, BCol, BMedia, BMediaAside, BMediaBody,
  } from 'bootstrap-vue'

  import VueApexCharts from 'vue-apexcharts'

  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BCardText,
      BCardHeader,
      BCardBody,
      BMedia,
      BMediaAside,
      BMediaBody,
      apexchart: VueApexCharts,
    },

    data() {
      return {
        statisticsData: [
          {
            icon: 'users',
            color: 'primary',
            title: '44',
            subtitle: 'Empleados al día',
          },
          {
            icon: 'clock',
            color: 'info',
            title: '0',
            subtitle: 'A tiempo hoy',
          },
          {
            icon: 'exclamation-triangle',
            color: 'danger',
            title: '0',
            subtitle: 'Retrasados hoy',
          },
          {
            icon: 'address-book',
            color: 'success',
            title: '6',
            subtitle: 'Solicitud de permisos o cambio de horario',
          },
        ],

        chartData: {
          series: [{
            name: 'Net Profit',
            data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
          }],

          chartOptions: {
            chart: {
              type: 'bar',
              height: 350,
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded',
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent'],
            },
            xaxis: {
              categories: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
            },
            yaxis: {
              title: {
                text: '$ (thousands)',
              },
            },
            fill: {
              opacity: 1,
            },
            tooltip: {
              y: {
                formatter(val) {
                  return `$ ${val} thousands`
                },
              },
            },
          },
        },
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>
